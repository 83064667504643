export default function() {
    if($('.youtube-video').length) {
        $('.youtube-video')[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
    }

    [].forEach.call(document.getElementsByClassName("iframe-lightbox-link"), function (el) {
        el.lightbox = new IframeLightbox(el, {
            onClosed: function() {
                /* hide your preloader */
                console.log("sluiten");
                $('.youtube-video')[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
            }
        });
    });

    $('.play-video').on("click", function() {
        $('.youtube-video')[0].contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
    });
}

