import Glide from "@glidejs/glide";

export default function() {

    if (document.querySelector('.glide-content-slider') === null) {
        return;
    }

    let contentNewsGlide = new Glide('.glide-content-slider', {
        type: 'carousel',
        startAt: 0,
        perView: 1,
    })

    contentNewsGlide.mount();
}
