export default function() {
    $('.file-input').change(function() {
        let id = ($(this).attr('id'));
        let label = $('#cv-name')
        if($('#resume')[0].files.length) {
            let file = $('#resume')[0].files[0].name;
            label.html(file);
        }
    });
}
