export default function() {
    const selector = '.slider .swiper';
    const ele = document.querySelector(selector);
    console.log(ele);

    if(ele !== null) {

        let data = ele.getAttribute('data-swiper') || {};
        data = data.replace(/'/g, '"');
        const options = JSON.parse(data);

        const swiper = new Swiper(selector, options);
    }
}