import Glide from "@glidejs/glide";

export default function() {

    if (document.querySelector('.glide-process-slider') === null) {
        return;
    }

    let processGlide = new Glide('.glide-process-slider', {
        type: 'slider',
        startAt: 0,
        perView: 3,
        gap: 57,
        rewind: false,
        peek: {
            before: 0,
            after: 246
        },
        breakpoints: {
            1400: {
                perView: 2,
                gap: 40,
                peek: {
                    before: 0,
                    after: 300
                },
            },
            1024: {
                perView: 2,
                gap: 40,
                peek: {
                    before: 0,
                    after: 100
                },
            },
            767: {
                perView: 1,
                gap: 40,
                peek: {
                    before: 0,
                    after: 100
                },
            },
            400: {
                perView: 1,
                gap: 20,
                peek: {
                    before: 0,
                    after: 60
                },
            }
        }
    })

    processGlide.mount();
}
