export default function() {
    if('.homepage-header') {
        let position = $(window).scrollTop();
        $(window).scroll(function() {
            let scroll = $(window).scrollTop();
            if (scroll > position) {
                $('.homepage-header .content').addClass('fly-away');
                $('.homepage-header .content').removeClass('fly-back');
            } else {
                $('.homepage-header .content').removeClass('fly-away');
                $('.homepage-header .content').addClass('fly-back');
            }
            position = scroll;
        });
    }
}
