export default function() {
    if (document.querySelector('#date') === null) {
        return;
    }

    let dtToday = new Date();

    $("#date").datepicker({
        changeMonth: true,
        changeYear: true,
        dateFormat: "dd-mm-yy",
        showAnim: "slideDown",
        minDate: dtToday,
        monthNames: [ "Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December" ],
        showOtherMonths: true,
        firstDay: 1,
        beforeShowDay: $.datepicker.noWeekends,
        monthNamesShort:  [ "Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December" ],
        dayNamesMin: [ "Ma", "Di", "Wo", "Do", "Vr", "Za", "Zo" ]
    });
}
