import Glide from "@glidejs/glide";

export default function() {

    if (document.querySelector('.glide-product-slider') === null) {
        return;
    }

    let contentGlide = new Glide('.glide-product-slider', {
        type: 'carousel',
        startAt: 0,
        perView: 1,
    })

    contentGlide.mount();
}
