export default function() {
    $('.overlay-point').each(function(i,e) {
        let left = $(e).data('x');
        let top = $(e).data('y');
        $(e).attr('style', `top:${top}%; left:${left}%;`);
    })

    $('.overlay-point').on('click', function() {

        setTimeout(function() {
            $('body').addClass('shrink');
            $('.info-popup').addClass('show');
        },10);

        let idx = $(this).data('idx') - 1;
        let items = json.items;
        let selectedItem = items[idx];

        $('.info-popup-inner').css("opacity", "0");

        $('#popup-title').html(selectedItem.title);
        $('#popup-intro').html(selectedItem.intro);
        $('#popup-image').html(selectedItem.image);
        $('#popup-rtetext').html(selectedItem.rtetext);
        $('#popup-button').html(selectedItem.buttontext);
        $('#popup-button').attr("href", selectedItem.buttonlink);

        $('.info-popup-inner').css("opacity", "1");
    });

    $('.info-popup__close').on('click', function() {
        $('body').removeClass('shrink');
        $('.info-popup').removeClass('show');
    });

    $(window).click(function() {
        if($('body').hasClass('shrink')) {
            $('body').removeClass('shrink');
            $('.info-popup').removeClass('show');
        }
    });
}
