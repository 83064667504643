//Import component JS below here

import AOS from 'aos';
import iframeLightbox from 'iframe-lightbox';

import headerInit from '../components/header/header';
import menuInit from '../components/header-with-submenus/header-with-submenu';
import sliderInit from '../components/slider/slider';
import homepageHeaderInit from "../components/homepage-header/homepage-header";
import newsSliderInit from "../components/news-slider/news-slider";
import productSliderInit from "../components/product-category/product-category";
import overlayPointsInit from "../components/image-with-info-points/image-with-info-points";
import videoHeader from "../components/video-header/video-header";
import stickyNavInit from "../components/about-intro-with-sticky-nav/about-intro-with-sticky-nav";
import historySliderInit from "../components/history-slider/history-slider";
import processSliderInit from "../components/process-slider/process-slider";
import contentSliderInit from "../components/news-detail-content/news-detail-content";
import contactHeaderInit from "../components/contact-header/contact-header";
import formDateFieldInit from "../components/contact-form-with-sidebar/contact-form-with-sidebar";
import vacancyFileUploadInit from "../components/cta-with-form/cta-with-form";

$(document).foundation();

$(document).ready(function () {
    headerInit();
    menuInit();
    //sliderInit();
    homepageHeaderInit();
    newsSliderInit();
    productSliderInit();
    overlayPointsInit();
    videoHeader();
    stickyNavInit();
    historySliderInit();
    processSliderInit();
    contentSliderInit();
    contactHeaderInit();
    formDateFieldInit();
    vacancyFileUploadInit();

    AOS.init({
        offset: 200, // offset (in px) from the original trigger point
        duration: 600, // values from 0 to 3000, with step 50ms
        easing: 'ease', // default easing for AOS animations
    });


    /* Deze code verwijderen of verplaatsen */
    /*$(function() {
        var videos  = $(".video");

        videos.on("click", function(){
            var elm = $(this),
                conts   = elm.contents(),
                le      = conts.length,
                ifr     = null;

            for(var i = 0; i<le; i++){
                if(conts[i].nodeType == 8) ifr = conts[i].textContent;
            }

            elm.addClass("player").html(ifr);
            elm.off("click");
        });
    });*/


});
