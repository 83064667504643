export default function() {

    if('.contact-header') {
        let position = $(window).scrollTop();
        $(window).scroll(function() {
            let scroll = $(window).scrollTop();
            if (scroll > position) {
                $('.contact-header .team-image-desktop').css("bottom", "-50px");
                $('.contact-header .team-image-mobile').css("bottom", "-50px");
            } else {
                $('.contact-header .team-image-desktop').css("bottom", "-1px");
                $('.contact-header .team-image-mobile').css("bottom", "-1px");
            }
            position = scroll;
        });
    }
}
