export default function() {
    $(function() {
        $('.header__menutrigger').click(function(){
            $('.header').toggleClass('menu-open');
        });
    });

    $(window).scroll(function() {
        var height = $(window).scrollTop();
        if (height > 130) {
            $(".header").addClass("scrolled");
        } else {
            $('.header').removeClass('scrolled');
        }
    });

}
