export default function() {
    const swiper = new Swiper('.news-swiper', {
        direction: 'horizontal',
        slidesPerView: 1.25,
        speed: 400,
        spaceBetween: 45,
        //autoplay: true,
        //loop: true,
        scrollbar: {
            el: ".swiper-scrollbar",
            hide: false,
        },
        breakpoints: {
            // when window width is >= 640px
            768: {
                slidesPerView: 2.25,
                spaceBetween: 64,
            },

            1025: {
                slidesPerView: 2.55,
                spaceBetween: 64,
            }
        }
    });
}
